import React, { Component } from 'react';
import moment from 'moment';
import {Bar} from 'react-chartjs-2';
import MyContext from './MyContext';
import InfoBlock from "./InfoBlock";

function formatDate(date) {
  if (date === null) return 'Очікується відповідь...';
  return moment(date).format('DD.MM.YY HH:mm');
}

function formatTime(duration) {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (days > 0) {
    return `${days} д ${hours} год ${duration.minutes()} хв`;
  } 
  if (hours === 0) {
    return `${duration.minutes()} хв`;
  }
  return `${hours} год ${minutes} хв`;
}

function getDuration(start, end) {
  let duration;
  if (!end) {
    // duration = moment.duration(moment() - moment(start));
    return null;
  } else duration = moment.duration(moment(end) - moment(start));
  return duration.asHours().toFixed(1);
}

function formatDuration(start, end) {
  let duration;
  if (!end) {
    duration = moment.duration(moment() - moment(start));
    return `Чекаємо ${formatTime(duration)} ...`
  } else duration = moment.duration(moment(end) - moment(start))
  return formatTime(duration);
}

function createLabels(history) {
  // return history.map((entry)=>[moment(entry.sended).format('HH:mm'), moment(entry.sended).format('DD.MM.YY')])
  let lastDate = '';
  return history.map((entry)=>{
    const currentDate = moment(entry.sended).format('DD.MM.YY');
    if (currentDate !== lastDate) {
      lastDate = currentDate;
      return [moment(entry.sended).format('HH:mm'), currentDate];
    }
    return moment(entry.sended).format('HH:mm');
  });
  
}

function formatAvgHours(hours) {
  const date = new Date(0,0);
  date.setMinutes(hours * 60);
  let fmtDate = `${date.getHours()} год ${date.getMinutes()} хв`;
  if (date.getDate() > 1) {
    fmtDate = `${date.getDate() - 1} дн ${fmtDate}`;
  }
  return fmtDate;
}

class History extends Component {
  static contextType = MyContext;
  render() {
    const { history, stats } = this.context;
    const { avgTimeBeforeTicket1 } = stats;
    const sourceHistory = history.filter(entry=>entry.operationalTime);
    if (!sourceHistory || (Array.isArray(sourceHistory) && sourceHistory.length === 0)) return (
      <div className="alert alert-warning">Чомусь немає даних від сервісу. Скоро полагодимо.</div>
    );

    const reversed = [...sourceHistory].reverse();
    const history2 = sourceHistory.map((entry) => {
      // console.log(moment.duration(moment(entry.receivedKvt1) - moment(entry.sended)).minutes());
      const sendedCol = <td><span className="small text-muted">{entry.charcode}</span><br />{ formatDate(entry.sended) }</td>
      if (entry.result === "FAILED") {
        return (
        <tr key={entry.sended} className="table-danger">
          {sendedCol}
          <td>Помилка серверу ДПС</td>
          <td>-</td>
          <td>Шлюз ДПС не працює</td>
        </tr>
        )
      }
      if (entry.subject && entry.subject.search("не прийнято") > -1) {
        return (
        <tr key={entry.sended} className="table-danger">
          {sendedCol}
          <td>Помилка в ПН</td>
          <td>-</td>
          <td>Помилка в ПН</td>
        </tr>
        )
      }
      const duration = moment.duration(moment() - moment(entry.sended));
      const hours = duration.asHours();
      let entryClass="";
      if (entry.receivedKvt1) {
        entryClass = "table-success";
      } else if (hours > 20) {
        entryClass = "table-danger"
      } else if (hours > 8) {
        entryClass = "table-warning"
      }
      return (
        <tr key={entry.sended} className={entryClass}>
          {sendedCol}
          <td>{ formatDate(entry.receivedKvt0) }</td>
          <td>{ formatDate(entry.receivedKvt1) }</td>
          <td>{ formatDuration(entry.sended, entry.receivedKvt1) }</td>
        </tr>
      )
    });

    const chartData = {
      labels: createLabels(reversed),
      datasets: [
        {
          label: 'Час до отримання квитанції №1 (годин)',
          backgroundColor: 'rgba(255,99,32,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: reversed.map((entry)=>getDuration(entry.sended, entry.receivedKvt1))
        },
      ]
    };
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            fontSize: 10,
          },
          scaleLabel: {
            display: true,
            labelString: 'Час надсилання податкової накладної'
          }
        }],
        yAxes: [{
          ticks: {
            fontSize: 10,
          },
          scaleLabel: {
            display: true,
            labelString: 'Годин до отримання квитанції №1'
          }
        }],
      }
    }
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6">
              <InfoBlock title="Середній час очікування" value={`${formatAvgHours(avgTimeBeforeTicket1)}`} description="Середній час очікування квитанцій №1 до податкових накладних в операційний час"/>
            </div>
            <div className="col-sm-6">
              <InfoBlock title="Операційний день" value="Пн-Пт з 8:00 до 20:00" description="Виключення якщо 15 число або останній день місяця припадають на вихідний, святковий або неробочий день" />
              {/* <div className="alert alert-warning" role="alert">
                Операційний день роботи ЄРПН: робочі дні з 8:00 до 20:00. Виключення якщо 15 число або останній день місяця припадають на вихідний, святковий або неробочий день.
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className='graph'>
                <Bar data={chartData} width={15} height={400} options={options} redraw={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className='tabdata'>
              <table className='history'>
                <thead>
                  <tr>
                    <td>Надіслана ПН</td>
                    <td>Отримано повідомлення</td>
                    <td>Отримана квитанція №1</td>
                    <td>З моменту надсилання(годин)</td>
                  </tr>
                </thead>
                <tbody>{history2}</tbody>
              </table>
            </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default History;
