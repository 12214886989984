import React, { Component } from "react";

class InfoBlock extends Component {
  render() {
    const { title, value, description } = this.props;
    return (
      <div
        className="card text-white bg-info mb-3"
      >
        <div className="card-header">{title}</div>
        <div className="card-body" style={{minHeight:150,}}>
          <h5 className="card-title text-center">{value}</h5>
          <p className="card-text text-center small">{description}</p>
        </div>
      </div>
    );
  }
}

export default InfoBlock;
