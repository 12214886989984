import React, { Component } from "react";
import MyContext from "./MyContext";

class ServiceStatus extends Component {
  static contextType = MyContext;
  render() {
    const { serviceStatus } = this.context;
    if (serviceStatus === "Ok") return null;
    return (
      <React.Fragment>
        <div className="row">
          <div className="alert alert-danger">
            Статус: {serviceStatus}. 
            Сервіс тимчасово недоступний. Нам дуже шкода. Скоро полагодимо.
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceStatus;
