import ky from "ky";

let API_ENTRYPOINT = "https://api.gatemon.com.ua";
if (process.env.NODE_ENV !== "production") {
  API_ENTRYPOINT = "https://api.gatemon.com.ua";
}

const kyClient = ky.extend({ prefixUrl: API_ENTRYPOINT})

const API = {
  getHistory: () => {
    return kyClient.get('tax-invoices')
      .then(data => {
        return data.json();
      })
  },
  getStats: () => {
    return kyClient.get('stats')
      .then(data => {
        return data.json();
      })
  },
}
export default API;