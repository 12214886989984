import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import History from './History';
import ServiceStatus from './ServiceStatus';
import MyContext from './MyContext';
import API from './api';

class App extends Component {
  state = {
    serviceStatus: 'Ok',
    history: [],
    stats: {},
  };

  update() {
    console.log('update history...');
    API.getHistory()
      .then((json) => {
        this.setState({
          history: json.history,
          serviceStatus: 'Ok',
        });
      })
      .catch(() => {
        this.setState({ serviceStatus: 'False' });
      });
    API.getStats().then((stats) => {
      this.setState({
        stats,
      });
    });
  }

  componentWillMount() {
    this.update();
    setInterval(() => {
      this.update();
    }, 60000);
    ReactGA.initialize('UA-53339061-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <MyContext.Provider value={this.state}>
        <header>
          <nav className='navbar navbar-dark bg-dark'>
            <a className='navbar-brand mb-0 h1' href='/'>
              GateMon - Моніторинг часу реєстрації ПН на сервері ДПС
            </a>
          </nav>
        </header>
        <div className='container-fluid'>
          <ServiceStatus />
          <div className='App'>
            {/* <iframe title="Поділитися" src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fgatemon.com.ua%2F&layout=button&size=large&width=119&height=28&appId" width="119" height="28" style={{border:"none",overflow:"hidden"}} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}
            <h2>Реєстрація податкових накладних </h2>
            <div className='row'>
              <div className='col-md-12'>
                <div className='alert alert-danger font-weight-bold'>
                  04.01.22 - У зв'язку зі{' '}
                  <a href='https://acskidd.gov.ua/news#305'>
                    скасуванням тестових сертифікатів
                  </a>
                  &nbsp; моніторинг часу реєстрації неможливий. Найближчим часом
                  додамо моніторинг доступності шлюзу ДПС, сервісу обміну РК ДПС
                  та шлюзу ФСС.
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='row'>
                  <div className='col-md-12'>
                    <a
                      href='https://fb.me/gatemonua'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        fill='#4267b2'
                        viewBox='0 0 24 24'>
                        <path d='M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z' />
                      </svg>
                    </a>{' '}
                    - наша група в Facebook, напишіть пропозиції та зауваження
                  </div>
                  <div className='col-md-12'>
                    <b>
                      Підключіть бота і він повідомить коли є проблеми з шлюзом:
                    </b>
                    <br />
                    {/* <a
                      href='https://m.me/112100820295547'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Бот Facebook Messenger
                    </a>
                    <br /> */}
                    <a
                      href='https://t.me/GatemonBot'
                      target='_blank'
                      rel='noopener noreferrer'>
                      Бот Telegram
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='d-flex align-items-center'>
                      <ReactGA.OutboundLink
                        eventLabel='fund10'
                        // to="https://privatbank.ua/ru/sendmoney?payment=54f9c10cb5"
                        to='https://secure.wayforpay.com/button/be7ecc55503a5'
                        target='_blank'
                        // trackerNames={['tracker2']}
                        rel='noopener noreferrer'
                        className='btn btn-warning -btn-lg mr-1'>
                        Підтримати проект 10 ₴
                      </ReactGA.OutboundLink>
                      <ReactGA.OutboundLink
                        eventLabel='fund20'
                        // to="https://privatbank.ua/ru/sendmoney?payment=54f9c10cb5"
                        to='https://secure.wayforpay.com/button/b471c319967ab'
                        target='_blank'
                        // trackerNames={['tracker2']}
                        rel='noopener noreferrer'
                        className='btn btn-warning -btn-lg mr-1'>
                        20 ₴
                      </ReactGA.OutboundLink>
                      <ReactGA.OutboundLink
                        eventLabel='fund50'
                        // to="https://privatbank.ua/ru/sendmoney?payment=54f9c10cb5"
                        to='https://secure.wayforpay.com/button/b71b3c7cad9ed'
                        target='_blank'
                        // trackerNames={['tracker2']}
                        rel='noopener noreferrer'
                        className='btn btn-warning -btn-lg mr-1'>
                        50 ₴
                      </ReactGA.OutboundLink>
                      <ReactGA.OutboundLink
                        eventLabel='fundX'
                        // to="https://privatbank.ua/ru/sendmoney?payment=54f9c10cb5"
                        to='https://secure.wayforpay.com/button/b04b84586fc79'
                        target='_blank'
                        // trackerNames={['tracker2']}
                        rel='noopener noreferrer'
                        className='btn btn-warning -btn-lg mr-1'>
                        ?? ₴
                      </ReactGA.OutboundLink>
                      <div className='px-2'>або</div>
                      <iframe
                        title='Поділитися'
                        src='https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fgatemon.com.ua%2F&layout=button&size=large&width=119&height=28&appId'
                        width='119'
                        height='28'
                        style={{ border: 'none', overflow: 'hidden' }}
                        scrolling='no'
                        frameBorder='0'
                        allowtransparency='true'
                        allow='encrypted-media'></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <p>Oновлюється автоматично раз в хвилину</p> */}
            <History />
            {/* <div className="row">
              <div className="col-md-12">
                <div id="disqus_thread"></div>
              </div>
            </div> */}
          </div>
        </div>
      </MyContext.Provider>
    );
  }
}

export default App;
